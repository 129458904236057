<template>
  <div class="out-div">
    <div class="equ-main animated fadeInUp">
      <img class="test-img" src="@/assets/image/test/famen.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      online: 1,
    };
  },
};
</script>

<style lang="less" scoped>
@keyframes imgRotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}
.out-div {
  padding: 0 48px;
  .equ-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .test-img {
      width: 1920px;
    }
  }
}
</style>
